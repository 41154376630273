@import "../../../../node_modules/swiper/modules/effect-fade/effect-fade.min.css";

.modal {
	position: absolute;
	transform: translate(25%);
	background-color: white;
	border-radius: 10px;
	outline: none;
	padding: 24px;
	box-shadow: 4px -32px 84px -62px;
	top: 10%;
	width: 900px;
}
@media only screen and (max-width: 600px) {
	.commentImg {
		/* width: 350px;
		height: 300px; */
		height: "75%" !important;
		width: "100%" !important;
	}
	.modal {
		width: 350px;
		margin-left: -90px;
	}
	.qrcode {
		width: 50px;
		height: 50px;
	}
}
@media only screen and (max-width: 400px) {
	.commentImg {
		/* width: 300px;
		height: 300px; */
		height: "75%" !important;
		width: "100%" !important;
	}
	.modal {
		width: 300px;
		margin-left: -80px;
	}
}

.swiper {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
}

.swiper-slide {
	text-align: center;
	font-size: 18px;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-button-next {
	color: rgb(64, 64, 64);
}

.swiper-button-prev {
	color: rgb(64, 64, 64);
}
.swiper-pagination-bullet {
	background: white;
}

.commentImg {
	max-height: 100% !important;
	/* width: 80%; */
}

.qrcode {
	width: 100px;
	height: 100px;
}
