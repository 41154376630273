.wallet_text {
	font-size: 20px;
}

.add_wallet_text {
	font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
	.wallet_text {
		font-size: 15px;
	}

	.add_wallet_text {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 480px) {
	.wallet_text {
		font-size: 12px;
	}
	.add_wallet_text {
		font-size: 1.2rem;
	}
}
