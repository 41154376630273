@font-face {
	font-family: "Arcade Classic";
	src: url("../../../assets/fonts/7bc71114a54a06cbaf716a3264b70bd6.ttf");
}

.logoFont {
	font-family: "Arcade Classic", arial;
	font-weight: 700;
	font-size: 25px;
}
