.modal {
	position: absolute;
	background-color: white;
	border-radius: 10px;
	outline: none;
	padding: 24px;
	box-shadow: 4px -32px 84px -62px;
	width: "100%";
	margin: auto;
	top: 5% !important;
}

.commentImg {
	width: 80%;
	max-height: 80% !important;
}
